@import "../../variables.scss";

.blog-post-preview {
  .preview-details {
    margin: auto;
    margin-top: 1.5em;
  }
  img {
    width: 100%;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    border-radius: 5%;
  }
  .author-date {
    p {
      display: inline;
    }
  }
  h2:hover,
  img:hover {
    cursor: pointer;
  }
  a {
    color: black;
  }
  a:hover {
    text-decoration: underline;
    color: black;
  }
}
