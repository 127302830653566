// colors 

$primary-color: #f5f2ef ;
$secondary-color: #509fc0 ;
$third-color: #0e5ca2;

// fonts 
$title-font: 'Merienda One', cursive;
$text-font: 'Shadows Into Light Two', cursive;

//mixins
@mixin centering ($width){
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: $width;
}


// //colors 
// $primarycolor:#509fc0 
// #f5f2ef
// #ec466c
// #e2f0ee
// #f6dddc
// #fbf9f4
// #f4c4ba
// #6dbeb0
// #a6d4ce
// #b7d7c9
// #e379e6
// #73cde7
// #0e5ca2
// #4fb2b1
// #d3dde0