@import "../../variables.scss";

.post{
    img{
        @include centering (100%);
        
    }
    h1{
        text-align: center;
    }
    .author-date{
        border-bottom: solid 1px black;
        margin-top: 1em;
        
    }
    .body{
        font-size: 1.25em;
    }
}
@media screen and (max-width: 760px){
.post{
    body{
        font-size: 1em;
    }
}

}

