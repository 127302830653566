@import "../../variables.scss";
.navigation{
    text-align: center;
    margin-left:-15px;
}
.navigation .left {
    display: inline-block;
    
    li {
    list-style-type: none;
    a {
        color: $primary-color;
    }
    a:hover {
        color: $third-color;
    }
}
}

.social-media { 
    header {
        text-align: center;
        font-weight: 900;
        margin-bottom: 1.5em;;
    }
    a {
        display: block;
        color: $primary-color;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        width: 66%;
        
    }
    a:hover {
        text-decoration: none;
        color: $third-color;
    }

   
}

footer {
    
    background-color: $secondary-color;
    color: $primary-color; 
    border-top: 5px solid $primary-color;
}
.footer-bottom {
    width: 100%;
    background-color: $primary-color;
    color: $secondary-color;
    text-align: center;

   .col a{
        text-decoration: none;
        color: $secondary-color;
        

    }
    .col a:hover {
        color: $third-color;
    }
}
.row {
   margin: 0px !important; 
}

.footer-title-col,
 .navigation{
    border-right: 3px solid $primary-color;
   
}
.footer-title,
.footer-tagline {
    padding-top: 1em;
    text-align: center;
}


.footer-top{
    padding-top:2em;
    padding-bottom:2em;

}
.extra-info a{
    color: $primary-color;
    text-align: center;
    
}
.extra-info a:hover {
    color: $third-color;
}

@media screen and (max-width: 640px){
    .navigation .left {
        display: inline-block;
        margin-left: -55px;
        li {
        list-style-type: none;
        a {
            color: $primary-color;
        }
        a:hover {
            color: $third-color;
        }
    }
    }
    
    .social-media { 
        header {
            text-align: center;
            font-weight: 900;
            margin-bottom: 1.5em;;
        }
        a {
            
            display: block;
        color: $primary-color;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        width: 66%;
           
            
        }
        a:hover {
            text-decoration: none;
            color: $third-color;
        }
    
       
    }
}


@media screen and (max-width: 490px){

    .social-media { 
        header {
            text-align: center;
            font-weight: 900;
            margin-bottom: 1.5em;;
        }
        a {
            
            display: block;
        color: $primary-color;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        width: 93%;
           
            
        }
}
.footer-bottom{
    font-size: .9em;
}
}