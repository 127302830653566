@import "../../variables.scss";
.navbar {
    height: 5.3em;
    background-color: $secondary-color;
    border-bottom: .2em solid $primary-color;
    
    .navbar-brand {
        color: $primary-color;
        font-family: $text-font;
        font-size: 2em;
    }
    .nav-links {
        margin-right: 5em;
        .nav-link {
            color: $primary-color;
            display: inline;
            font-family: $text-font
        }
        .nav-link:hover{
            color: $third-color;
        }
    }
    
    
}
.navbar-collapse{
    background-color: $secondary-color;
    border-bottom: .2em solid $primary-color ;
    z-index:100;
    .nav-item{
        margin-left:1em;
    }
}