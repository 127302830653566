@import "../../variables.scss";

.dear-you{
    padding: 8em;
    background-image: url("../../assets/images/mountains.png");
    background-size:cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    h2 {
        font-size: 3em;

    }
    
    .dear-you-content{
        font-weight: 600;
        background: rgba(201, 201, 201, 0.425);
        padding: 3em;
        border-radius: 1em;

        p{
            font-size: 1.2em;
        }
    }
    
}

@media screen and (max-width: 640px){
    .dear-you{
        padding: 0em;
        .dear-you-content{
            p{
                font-size: 1em;
            }
        }
    }
    
   

}