@import "../../variables.scss";

.title-parallax {
font-family: $text-font;
    .parallax{
        background-image: url(../../assets/images/yesi-parallax.jpg);
        height: 40em;
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    
}
    .jumbotron {
        background: rgba(50,50,50, 0.3);
        color: $primary-color;
        text-align: center;
        position: absolute;
        top: 10em;
        left: 0;
        right: 0;
        @include centering (50%);
        .lead {
        font-size: 2em;
        }
    }
}

@media screen and (max-width: 640px){
   
    .title-parallax{

        height: 30em;
        .jumbotron{
            top: 12.5em;
            @include centering (95%);
            .display-3{
                font-size: 3em;
            }
            .lead{
                font-size:1.6em
            }
        }
        
    }
    
}



@media screen and (max-width: 400px){
.title-parallax{
    .parallax{
        background-image: url(../../assets/images/yesi-parallax.jpg);
        height: 30em;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    
    }
}

}