@import "../../variables.scss";

.contact-me {
    padding: 2em;
    margin-top:3em;
    height: 37em;
    .btn {
        background-color: $secondary-color;
        border-color: $secondary-color;
    
    }
    .btn:hover{
        background-color: $third-color;
        border-color: $third-color;
    }
    span{
        margin-left:.5em;
    }
}