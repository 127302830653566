@import "../../variables.scss";

.home-about-me {
    padding-top: 3em;
    background-color: $primary-color;
    color: black;
    .hero-img {
  
        overflow: hidden;
       
     }
     .hero-img img {
         width:100%;
         transition: all .5s;
     }
     .hero-img:hover img {
         transform: scale(1.1);
         -webkit-transition: scale(1.1);
         transition: scale(1.1);
         -moz-transition: scale(1.1);
         -ms-transition: scale(1.1);
         -o-transition: scale(1.1);
     }
     .social-icons {
        margin-top:15px;
        text-align: center;
         
        padding:15px;
         transition: all .5s;
         -webkit-transition: all .5s;
         -moz-transition: all .5s;
         -o-transition: all .5s;
        
     }
     
     .fab {
         width: 40px;
         height:40px;
         line-height: 40px;
         background: none;
         color: #333333;
         cursor: pointer;
         border: 1px solid #333333;
         transition: all .5s;
         margin-right: 3px;
         margin-left: 3px;
        
     }
     .fab:hover {
         background-color: $secondary-color;
     }
     .home-about-content{
         h3{
             font-size: 4em;
         }
         p{
             font-size: 1.5em;
         }
         .btn{
             margin-bottom: 2em;
         }
     }
}

@media screen and (max-width: 640px){
.home-about-me{
    .home-about-content{
        h3{
            font-size: 2em;
        }
        p{
            font-size: 1em;
        }
        .btn {
            margin-bottom: 1em;
        }
    }
}

}