@import "../../variables.scss";

.about-me{
    h1{
        text-align: center;
    }
    img{
        width:25%;
        float:left;
        margin-right:1em;
        
    }
    .content{
        margin-bottom: 5em;
        padding-right: 2em;
        margin-left: 1em;
    }
    
    
}